import { Component, For, Show } from "solid-js";
import { BreadcrumbFields } from "./Breadcrumb.block";
import { A } from "@solidjs/router";

const Breadcrumb: Component<BreadcrumbFields & { class?: string }> = (
  props
) => {
  return (
    <Show when={props.breadcrumbs}>
      <div
        class="flex flex-row gap-3 text-sm text-gray-400 py-3"
        classList={{ [`${props.class}`]: !!props.class }}
      >
        <For each={props.breadcrumbs}>
          {(item) => (
            <A
              href={item.url || "#"}
              class={`after:content-['/'] after:ml-3 after:last:content-[''] block last:text-black last:pointer-events-none`}
            >
              {item.pageTitle}
            </A>
          )}
        </For>
      </div>
    </Show>
  );
};

export default Breadcrumb;
